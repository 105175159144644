'use client'

import React from 'react'
import {useTranslations} from 'next-intl'
import fp from 'lodash/fp'
import Fuse from 'fuse.js'
import {useDebounce} from 'use-debounce'
import {css, useTheme} from '@emotion/react'
import {AnimatePresence, motion} from 'framer-motion'
import Scrollbars from 'react-custom-scrollbars-2'
import {Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, List, ListItemButton} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'

import {ToolCategories} from '../../../config/categories.config'
import ToolList from '../../modules/ToolList'
import Title from '../../modules/Title'
import useToggle from '../../../hooks/useToggle'
import useAllToolInfos from '../../../hooks/useAllToolInfos'
import SearchField from '../../elements/SearchField'
import Paper from '../../elements/Paper'
import {Link} from '../../../app/i18n/routing'
import {useDeveloperMode} from '../../../contexts/developerMode'
import useLeaveSiteDetect from '../../../hooks/useLeaveSiteDetect'

const MaxToolCount = 5

function Navbar({currentToolKey, ...props}) {
  const [expanded, expandedToggle] = useToggle(true)

  const theme = useTheme()
  const styles = {
    root: css`
      height: 100%;
      display: flex;
      flex-direction: column;
      background: ${theme.palette.primary.light};
      width: 360px;

      position: relative;
      padding-bottom: 56px;
    `,

    header: css`
      background: ${theme.palette.primary.dark};
      padding: ${expanded ? '8px 16px' : '4px 4px'};
    `,

    searchField: css`
      margin: 8px 0;
    `,

    title: css`
      margin: 0 auto;
    `,

    content: css`
      padding: 16px;
    `,

    footer: css`
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      padding: 8px;
      text-align: right;

      display: flex;
      justify-content: right;
      align-items: center;
    `,
  }

  const t = useTranslations()

  const [searchText, setSearchText] = React.useState('')
  const onSearchTextChange = (searchText) => {
    setSearchText(searchText)
  }

  const allToolInfos = useAllToolInfos()

  const [debouncedSearchText] = useDebounce(searchText, 20)
  const filteredToolInfos = React.useMemo(() => {
    if (debouncedSearchText) {
      const fuse = new Fuse(allToolInfos, {
        keys: [
          {name: 'label', weight: 1},
        ],
        includeMatches: true,
      })

      return highlight(fp.flow(
        fp.take(MaxToolCount),
      )(fuse.search(debouncedSearchText)))
    } else {
      return allToolInfos
    }
  }, [allToolInfos, debouncedSearchText])

  const categoryToolInfos = React.useMemo(() => (
    ToolCategories
      .filter(toolCategory => toolCategory !== 'recent-used')
      .map(toolCategory => ({
        key: toolCategory,
        value: toolCategory,
        label: t(`tool-category.${toolCategory}`),
        tools: filteredToolInfos
          .filter(onlineTool => onlineTool.category === toolCategory)
      }))
      .filter(toolCategory => toolCategory.tools.length > 0)
  ), [t, filteredToolInfos])

  return (
    <motion.nav
      id="nav"
      css={styles.root}
      initial={false}
      animate={{width: expanded ? 360 : 56}}
      {...props}
    >
      <motion.div
        css={styles.header}
        initial={false}
        animate={{padding: expanded ? '8px 16px' : '4px 4px'}}
      >
        <Title
          css={styles.title}
          title={t('site-name')}
          expanded={expanded}
        />
        {expanded ? (
          <div css={styles.searchField}>
            <SearchField
              id="tool-search"
              placeholder={t('tool-layout.search-placeholder')}
              query={searchText}
              onQueryChange={onSearchTextChange}
            />
          </div>
        ) : null}
      </motion.div>

      <AnimatePresence>
        {expanded ? (
          <Scrollbars universal autoHide>
            <motion.div
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              css={styles.content}
            >
              {searchText.length > 0 ? (
                <SearchPanel
                  css={styles.section}
                  tools={filteredToolInfos}
                />
              ) : null}

              {searchText.length === 0 ? (
                <div css={css`
                  display: flex;
                  flex-direction: column;
                `}>
                  {categoryToolInfos.map(categoryToolInfo => (
                    <CategoryPanel
                      key={categoryToolInfo.key}
                      currentToolKey={currentToolKey}
                      categoryToolInfo={categoryToolInfo}
                    />
                  ))}
                </div>
              ) : null}
            </motion.div>
          </Scrollbars>
        ) : null}
      </AnimatePresence>

      <motion.div
        css={styles.footer}
        animate={{
          height: expanded ? 'initial' : '100%',
        }}
      >
        <IconButton onClick={expandedToggle}>
          {expanded ? <FaChevronLeft/> : <FaChevronRight/>}
        </IconButton>
      </motion.div>
    </motion.nav>
  )
}


function SearchPanel({tools, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
      padding: 16px;
      background: ${theme.palette.surface.main};
    `,

    header: css`
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 8px;
    `,
  }

  const t = useTranslations()

  return (
    <Paper css={styles.root} {...props}>
      <div css={styles.header}>{t('tool-layout.search-results')}</div>
      <Divider/>
      <ToolList tools={tools}/>
    </Paper>
  )
}


function CategoryPanel({currentToolKey, categoryToolInfo, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
    `,

    header: css`
      font-size: 1.2rem;
      font-weight: 600;
    `,

    tool: css`
      b {
        color: ${theme.palette.secondary.main};
      }
    `,

    item: css`
      padding: 16px;
    `,

    privateTag: css`
      margin-left: 4px;
      color: ${theme.palette.secondary.dark};
      font-size: 12px;
    `
  }

  const defaultExpanded = React.useMemo(() => {
    return categoryToolInfo.tools.map(tool => tool.key).includes(currentToolKey)
  }, [categoryToolInfo.tools, currentToolKey])

  const developMode = useDeveloperMode()

  const [handleLink, LeaveSiteDialog] = useLeaveSiteDetect()

  return (
    <>
      <Accordion css={styles.root} defaultExpanded={defaultExpanded} {...props}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div css={styles.header}>{categoryToolInfo.label}</div>
        </AccordionSummary>
        <AccordionDetails css={styles.detail}>
          <List css={styles.tool}>
            {
              categoryToolInfo.tools.map(tool => (
                <li key={tool.label}>
                  <Link href={tool.url} passHref legacyBehavior>
                    <ListItemButton
                      css={styles.item}
                      selected={tool.key === currentToolKey}
                      component="a"
                      onClick={e => handleLink(e, tool.url)}
                    >
                      <span dangerouslySetInnerHTML={{__html: tool.label}}/>
                      {developMode && tool.isPrivate ? (
                        <span css={styles.privateTag}>[P]</span>
                      ) : null}
                    </ListItemButton>
                  </Link>
                </li>
              ))
            }
          </List>
        </AccordionDetails>
      </Accordion>

      <LeaveSiteDialog/>
    </>
  )
}


function highlight(fuseSearchResult) {
  const generateHighlightedText = (inputText, regions = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<b>`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</b>',
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }

  return fuseSearchResult
    .filter(({matches}) => matches && matches.length)
    .map(({item, matches}) => {
      return fp.flow(
        ...matches.map(match => fp.set(match.key, generateHighlightedText(match.value, match.indices)))
      )(item)
    })
}


export default React.memo(Navbar)
